<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="reports"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách báo cáo
              <v-spacer></v-spacer>
            </v-card-title>
          </v-toolbar>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span >{{ formatDateTime(item.created_at) }}</span>
        </template>
        <template v-slot:item.content="{item}">
          <a :href="`${url}${item.action}`" class="h">{{ item.content }}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-btn @click="approveItem(item)" class="mr-2 primary">Phê duệt</v-btn>
            <v-btn @click="denyItem(item)">Từ chối</v-btn>
          </div>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search found no results.
          </v-alert>
        </template>
      </v-data-table>
    </template>

    <!-- DIALOG APPROVE  -->
    <v-dialog v-model="dialogApprove" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc chắn muốn phê duyệt báo cáo này?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="approveItemConfirm"
          >Phê duyệt
          </v-btn
          >
          <v-btn color="blue darken-1" text @click="closeApprove"
          >Hủy
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG DENY -->
    <v-dialog v-model="dialogDeny" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Bạn có chắc chắn muốn từ chối báo cáo này?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="denyItemConfirm"
          >Từ chối
          </v-btn
          >
          <v-btn color="blue darken-1" text @click="closeDeny"
          >Hủy
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {validationMixin} from "vuelidate";
import {SET_LOADING} from "@/store/loading.module";

import {APPROVE_REPORT, DENY_REPORT, GET_REPORT_LIST} from "@/store/report.module";
import {DELETE_LESSON} from "@/store/lesson.module";
import {ERROR} from "@/store/alert.module";


export default {
  mixins: [validationMixin],
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: " Thông báo ", value: "content"},
      {text: "Ngày tạo", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    dialogApprove: false,
    dialogDeny: false,
    indexApproved: 0,
    idApproved: 0,
    indexDeny: 0,
    idDeny: 0,
    itemApprove: {},
    url: ''
  }),

  computed: {
    ...mapGetters({
      reports: "listReport",
    }),
  },
  mounted() {
    this.url = process.env.VUE_APP_URL
    this.getReport();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Báo cáo vi phạm", route: "Báo cáo vi phạm"},
    ]);
  },
  methods: {
    getReport() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_REPORT_LIST, {per_page: 100})
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },

    approveItem(item) {
      this.itemApprove = item
      this.indexApproved = this.reports.indexOf(item);
      this.idApproved = item.id;
      this.dialogApprove = true
    },
    approveItemConfirm() {
      let payload = {
        id: this.idApproved,
        status: 'approve'
      }
      this.$store.dispatch(APPROVE_REPORT, payload).then((data) => {
        if (data.status) {
          this.reports.splice(this.indexApproved, 1);
          this.reports.forEach((item, index) => {
            if (item.report_config_id == this.itemApprove.report_config_id &&
                item.reportable_id == this.itemApprove.reportable_id &&
                item.reportable_type == this.itemApprove.reportable_type) {
              this.reports.splice(index, 1);
            }
          })
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });
      this.closeApprove()
    },
    closeApprove() {
      this.dialogApprove = false
    },

    denyItem(item) {
      this.indexDeny = this.reports.indexOf(item);
      this.idDeny = item.id;
      this.dialogDeny = true
    },
    denyItemConfirm() {
      let payload = {
        id: this.idDeny,
        status: 'deny'
      }
      this.$store.dispatch(DENY_REPORT, payload).then((data) => {
        if (data.status) {
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }

        }
      });
      this.closeDeny()
    },
    closeDeny() {
      this.dialogDeny = false
    },
    formatDateTime(date) {
      if (!date) return null
      const newDate = new Date(date)
      const [year, month, day, hour, minute, millisecond] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate(),
        newDate.getHours(), newDate.getMinutes(), newDate.getMilliseconds()]
      const hour_format = hour > 9 ? hour : `0${hour}`
      const minute_format = minute > 9 ? minute : `0${minute}`
      const millisecond_format = millisecond > 9 ? millisecond : `0${millisecond}`
      return `${hour_format}:${minute_format}:${millisecond_format} ${day}/${month}/${year}`
    },
  },
};
</script>

<style lang="scss">
.report-item {
  div, p, h1, h2, h3, h4, h5, h6, a {
    display: inline-block;
  }
}
</style>
